<template>
  <div class="mx-1">
    <!-- Working Days -->
    <b-overlay
        :show="addLoader"
        rounded="sm"
    >
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group
              label="Days"
          >
          </b-form-group>
        </b-col>
        <b-col class="mb-1" cols="12" md="12">

          <b-button
              v-for="(day,index) in days"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-icon rounded-circle "
              variant="white"
              @click="toggleDaySelection(day)"
          >
            <b-avatar
                :class=" isUnAvailabilitiesAvailableForThisDay(day.day) ? 'text-white bg-primary ' : 'text-dark border-secondary'"
                :style="isUnAvailabilitiesAvailableForThisDay(day.day) ? 'backgroundColor:#1B9AAA !important' : ''"
                :text="day.text"/>
          </b-button>


        </b-col>
      </b-row>

      <!-- Unavailable Hours -->

      <b-row class="pt-2">
        <b-col v-show="unavailabilities.length" cols="12" md="12">
          <b-form-group
              label="Unavailable Hours"
          >
          </b-form-group>
        </b-col>

        <b-col class="mb-1" cols="12" md="12">
        </b-col>
      </b-row>
      <validation-observer
          ref="unavailabilityForm"
          #default="{ handleSubmit }"
      >
        <b-form
            ref="form"
            class="repeater-form "
        >
          <!-- Add Button -->
          <div v-for="(day,index) in unavailabilities" class="mb-1">
            <b-row v-for="(time,innerIndex) in day.times">
              <b-col v-if="innerIndex === 0" class="mt-1" cols="12 pr-0" md="2">
                <h6>{{ day.day }}</h6>
              </b-col>
              <b-col v-else class="mt-1" cols="12 pr-0" md="2">
                <h6></h6>
              </b-col>

              <b-col class="mb-1" cols="12" md="4">
                <div class="set-mb">
                  <validation-provider
                      #default="{ errors }"
                      :vid="'StartTime'+index+innerIndex"
                      name="Start time"
                      rules="required"
                  >
                    <flat-pickr
                        v-model="unavailabilities[index].times[innerIndex].start"
                        :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i',}"
                        class="form-control"
                        placeholder="Start time"
                    />
                    <!--                    <b-form-timepicker v-model="unavailabilities[index].times[innerIndex].start" locale="en"-->
                    <!--                                       placeholder="Start time"/>-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

              </b-col>
              <b-col class="mb-1" cols="12" md="4">
                <div class="set-mb">
                  <validation-provider
                      #default="{ errors }"
                      :vid="'EndTime'+index+innerIndex"
                      name="End time"
                      rules="required"
                  >
                    <flat-pickr
                        v-model="unavailabilities[index].times[innerIndex].end"
                        :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i',}"
                        class="form-control"
                        placeholder="End time"/>

                    <!--                    <b-form-timepicker v-model="unavailabilities[index].times[innerIndex].end" locale="en"-->
                    <!--                                       placeholder="End time"/>-->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>

              <b-col v-if="day.times.length === innerIndex + 1" class="mb-1" cols="12" md="2">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon rounded-circle p-0"
                    size="20"
                    variant="outline-white"
                    @click="addSlot(unavailabilities[index].times)"
                >
                  <feather-icon icon="PlusCircleIcon" size="17"/>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon rounded-circle"
                    size="20"
                    variant="outline-white"
                    @click="removeSlot(index,innerIndex)"
                >
                  <feather-icon icon="MinusCircleIcon" size="17"/>
                </b-button>
              </b-col>

              <b-col v-else class="mb-1" cols="12" md="2">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon rounded-circle pl-0"
                    size="20"
                    variant="outline-white"
                    @click="removeSlot(index,innerIndex)"
                >
                  <feather-icon icon="MinusCircleIcon" size="17"/>
                </b-button>
              </b-col>


            </b-row>
          </div>
        </b-form>
      </validation-observer>

      <!-- Save & Cancle Button -->

      <div class="d-flex">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click.prevent="submitForm"
        >
          Save
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="text-primary ml-1 shadow"
            type="button"
            variant="outline-white"
            @click="cancel()"
        >
          Cancel
        </b-button>
      </div>

    </b-overlay>
  </div>
</template>

<script>

import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import {heightTransition} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import profile from '@/apis/modules/profile'
import {days} from '@/constants/config'
import MomentMixin from '@/mixins/MomentMixin'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment'

export default {
  components: {
    flatPickr,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BBadge,
    BCard,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BTable,
    BFormTimepicker,
    BOverlay
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition, MomentMixin],
  data () {
    return {
      addLoader: false,
      days: null,
      unavailabilities: [],
      nextTodoId: 1,
      value: ''
    }
  },
  async mounted () {
    this.days = days
    this.initTrHeight()
    await this.getMyUnAvailabilities()
  },
  created () {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    isUnAvailabilitiesAvailableForThisDay () {
      return (day) => _.some(this.unavailabilities, {day: day})
    }
  },
  methods: {
    async getMyUnAvailabilities () {
      this.addLoader = true
      try {
        this.unavailabilities = (await profile.getMyUnAvailabilities()).data.data.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
          /*  day_number: m.day_number,
            id: m.id,
            user_id: m.user_id,*/
            start: this.momentFormat(m.start, 'HH:mm'),
            end: this.momentFormat(m.end, 'HH:mm')
          }))
        }))

        this.addLoader = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.addLoader = false
      }
    },
    async submitForm () {
      this.addLoader = true
      this.$refs.unavailabilityForm.validate().then(async success => {
        if (success) {
          try {
            //convert to utc
            this.utcTime = this.unavailabilities.map((x) => ({
              day_number: this.getWeekDayNumberViaDate(x),
              times: x.times.map((m) => ({
                start: this.getUTCConvertedTime(x.day_number, m.start),
                end: this.getUTCConvertedTime(x.day_number, m.end)
              }))
            }))
            // if (this.utcTime.length === 0){
            //   this.showErrorMessage('Please add at least one new  Unavailability')
            // }else {
              let data = await profile.updateUnAvailabilities({un_availabilities: this.utcTime}, this.$route.params.id)
              this.showSuccessMessage('Unavailability updated successfully')
              this.$emit('close')
            // }
            this.addLoader = false
          } catch (error) {
            this.convertAndNotifyError(error)
            this.addLoader = false
          }
        }
      })
      this.addLoader = false
    },
    toggleDaySelection (selectedDay) {
      const index = this.unavailabilities.findIndex(obj => obj.day === selectedDay.day)
      if (index >= 0) {
        this.unavailabilities.splice(index, 1)
      } else {
        this.unavailabilities.push({
          day_number: selectedDay.day_number,
          day: selectedDay.day,
         // value: selectedDay.value,
          times: [{}]
        })
      }
    },
    async removeSlot (index, innerIndex) {
      await this.unavailabilities[index].times.splice(innerIndex, 1)
      if (this.unavailabilities[index].times.length === 0) this.unavailabilities.splice(index, 1)
    },
    cancel () {
      this.$emit('unavailabilityInfo')
    },
    addSlot (slotToBeAddTime) {
      slotToBeAddTime.push({})
      /* this.unavailabilities.push({
         id: (this.nextTodoId += this.nextTodoId)
       })*/

      /*  this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })*/
    },
    removeItem (index) {
      this.unavailabilities.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

span.b-avatar {
  background-color: transparent !important;
}

.bg-primary-avatar {
  background-color: #1B9AAA !important;
}
</style>
